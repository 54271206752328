// Colors
@theme-color: #315ba7;
@background-color: #e1eaf5;
@background-image: url("/static/dist/img/background.jpg");
@box-shadow: 0 0 5px rgba(65, 67, 144, 0.15);
@header-color: #5f6988;

// Fonts
@body-font: 'Poppins', sans-serif;

// Animation
@transition: all .3s ease-out;
